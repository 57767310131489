<script>
import { mapState } from 'pinia';
import { useProcessesStore } from '@/stores/index.js';
import useFormsHeader from '@/stores/forms/header';

export default {
  computed: {
    ...mapState(useProcessesStore, {
      processTitle: 'processName',
    }),
    ...mapState(useFormsHeader, {
      pageTitle: 'pageTitle',
      levelbarActionsComponent: 'levelbarActionsComponent',
      breadcrumbsState: 'breadcrumbs',
    }),
    title() {
      return this.pageTitle || this.$route.meta.title;
    },
    actionsComponent() {
      return this.levelbarActionsComponent;
    },

    showBreadcrumbs() {
      return (
        !this.breadcrumbs.some((item) => item.title && item.title.lazy)
        && this.$route.meta.breadcrumb
      );
    },

    breadcrumbs() {
      let result = this.$route.matched
        .filter((item) => item.name && !item.meta.skip)
        .map((item) => {
          const breadcrumb = this.breadcrumbsState.filter((b) => b.name === item.name)[0];
          return {
            name: item.name,
            title:
              (breadcrumb ? breadcrumb.title : item.meta.breadcrumb)
              || (item.meta.rename && this.processTitle),
          };
        });

      const first = result[0];
      if (first && first.name !== 'Home') {
        result = [{ name: 'Home' }, ...result];
      }

      return result;
    },

    formsBreadCrumbs() {
      if (this.breadcrumbs.length < 3) {
        return [];
      }

      return this.breadcrumbs.slice(2);
    },
  },
};
</script>

<template>
  <div
    v-if="showBreadcrumbs"
    class="forms-nav levelbar"
    :class="$route.meta.levelbarClass"
  >
    <nav class="flex-parent app-levelbar">
      <div class="levelbar-container">
        <nav
          class="level-item long-and-truncated"
          aria-label="breadcrumbs"
        >
          <ul class="levelbar-items">
            <li
              v-for="(item, index) in formsBreadCrumbs"
              :key="index"
              class="levelbar-item"
              :class="{ 'is-active': index === formsBreadCrumbs.length - 1 }"
            >
              <div class="levelbar-item-icon">
                <img src="@/assets/angle-right.svg">
              </div>
              <router-link
                :to="{ name: item.name }"
                exact
                :title="item.title || item.name"
                class="levelbar-item-link"
              >
                <span>{{ item.title || item.name }}</span>
              </router-link>
            </li>
          </ul>
        </nav>
        <div
          v-if="actionsComponent"
          class="levelbar__actions"
        >
          <component :is="actionsComponent" />
        </div>
      </div>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
@import '~bulma/sass/utilities/mixins';

.levelbar {
  margin-bottom: 24px;

  &-forms-submissions {
    padding-right: 10px;
  }

  .flex-parent {
    display: flex;
    align-items: baseline;
  }

  .long-and-truncated {
    flex: 1;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    display: flex;

    @include mobile {
      flex: auto;
    }
  }

  &__actions {
    @include mobile {
      margin-left: auto;
    }
  }

  &-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;

    @include mobile {
      flex-wrap: wrap;
    }
  }

  &-items,
  &-item {
    display: flex;
    align-items: center;
  }

  &-items {
    width: 100%;
    column-gap: 12px;
  }

  &-item {
    display: flex;
    column-gap: 12px;
    min-width: 13ch;
    flex: 1;
    max-width: max-content;
    flex-shrink: 0;
    flex-basis: 0;

    &:first-child {
      flex-grow: 3;
      min-width: 8ch;

      .levelbar-item-icon {
        display: none;
      }
    }

    &:last-child {
      flex-grow: 10;
    }

    a,
    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-icon {
      height: 100%;
      min-width: 0.8125rem;
      max-width: 0.8125rem;
      position: relative;
      top: 2px;

      img {
        object-fit: contain;
        width: 100%;
        display: block;
      }
    }

    &-link {
      font-size: 1.5rem;
      font-weight: 600;

      &.is-active {
        color: var(--color-secondary);
        pointer-events: none;
      }
    }
  }
}
</style>
